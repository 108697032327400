
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import charges from '@/services/charges'

import { currencyFilter } from '@/utils/string'

import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Charge } from '@/models/dto/Charge'
import Placeholder from '@/views/Placeholder.vue'
import auth from '@/store/modules/auth'
import DateMixin from '@/mixins/DateMixin'

@Component({
  components: { CUCollectionTable },
})
export default class ChargesList extends mixins(DateMixin) {
  mounted(): void {
    EventBus.$on('charges:open-reservation', this.openReservation)
  }

  beforeDestroy(): void {
    EventBus.$off('charges:open-reservation', this.openReservation)
  }

  tableView = charges.tableView
  tableItemCount = 0

  openReservation(row: Charge): void {
    sidebar.push({
      component: Placeholder,
      title: `Reservation ID ${row.reservation.managedId}`,
    })
  }

  handleUpdateCount(count: number): void {
    this.tableItemCount = count
  }

  actions: TableAction[] = []

  columns: DataTableColumn[] = [
    {
      _t_id: '424a5755-1ced-4a63-bd38-6790be221edc',
      text: 'Transaction ID',
      value: 'transactionId',
    },
    {
      _t_id: '006feb07-46d0-497f-b5d7-2d3808460bc3',
      text: 'Transaction Type',
      value: 'transactionType',
    },
    {
      _t_id: '5bf409cb-6176-41ac-8170-f02c1ac4138e',
      text: 'Amount',
      value: 'amount',
      computedText: (row): string => currencyFilter(row.amount),
    },
    {
      _t_id: '5d47454a-ca97-4d03-9ee5-690534798db6',
      text: 'Created On',
      value: 'createdOn',
      computedText: (row): string =>
        this.formatMediumDate(row.createdOn, { tz: auth.getUserTimeZone }),
    },
    {
      _t_id: 'ffb4fea0-60da-45db-ad7f-b65a327fed91',
      text: 'Reservation ID',
      value: 'reservation/managedId',
      type: 'link',
      clickEvent: 'charges:open-reservation',
    },
  ]
}

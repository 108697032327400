
import { Vue, Component } from 'vue-property-decorator'
import ChargesList from '@/components/ChargesList.vue'
import Main from '@/layouts/Main.vue'

@Component({
  components: { Main, ChargesList },
  metaInfo: { title: 'Charges' },
})
export default class Charges extends Vue {}
